.ti-trade-config-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    padding-top: var(--spacing-4);
}

.ti-trade-config-container {
    display: grid;
    width: 75%;
    grid-template-rows: repeat(1, 1fr);
    gap: var(--spacing-2);
}

.ti-trade-config-info-container {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-2);
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    /* background-color: var(--background-secondary); */
    color: var(--text-primary);
}

.ti-trade-config-info-container > * {
    margin: var(--spacing-2);
}

.ti-trade-config-info-button {
    width: 50%;
}

@media screen and (min-width: 769px) {
    /* No Trades */
    .ti-trade-config-wrapper {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
    }

    .ti-trade-config-container {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: var(--spacing-2);
    }

    .ti-trade-config-info-container {
        display: flex;
        flex-direction: column;
        padding: var(--spacing-2);
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 10px;
        /* background-color: var(--background-secondary); */
        color: var(--text-primary);
    }

    .ti-trade-config-info-container > * {
        margin: var(--spacing-2);
    }

    .ti-trade-config-info-button {
        width: 50%;
    }
}