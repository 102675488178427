.account-info-content {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    padding: var(--spacing-4);
    /* align-items: center; */
}

.account-info-content-compact {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    gap: var(--spacing-4);
}

.user-hero-banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.user-hero-info-wrapper {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    justify-content: center;
    gap: var(--spacing-2);
}

.user-hero-icon {
    color: var(--text-primary);
}

.user-hero-info-column {
    display: flex;
    flex-direction: column;
}

.user-hero-info-column h1 {
    /* font-size: var(--font-size-3xl); */
    font-weight: var(--font-weight-semibold);

}

.user-hero-info-user-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-1);
}

.user-account-link {
    color: var(--color-primary-a0);
}
/* TradingView Profile */

.account-tradingview {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
}

.account-tradingview-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--spacing-2);
}

.account-tradingview-profile-input {
    display: flex;
    flex-grow: 1;
    border-radius: 10px;
    border: 1px solid var(--color-line-grey);
    padding: var(--spacing-1);
}
/* User Description Container */

.account-description-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
}

.acount-description-title {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-1);
    align-items: center;
}
.account-description-info-icon {
    color: var(--color-grey);
}

.account-description-textarea {
    border: 1px solid var(--color-line-grey);
    border-radius: 10px;
    padding: var(--spacing-1);
    resize: none;
    min-height: 100px;
}

.account-description-button-container {
    display: flex;
    justify-content: flex-end;
}

/* Account Preferences */

.account-preferences {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
}

.account-preference-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
}

.account-preference-switch-container {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-1);
    align-items: center;
}

/* Account Info Container */

.account-info-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
}

.account-info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--spacing-2);
}

.account-info-grid-item {
    display: flex;
    flex-direction: column;

}