.--cell {
    color: var(--text-primary);
    display: flex;
    align-items: center;
}

.--cell-id:hover{
    cursor: pointer;
    box-shadow: inset 0 0 10px var(--box-shadow-color);
}
