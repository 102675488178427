.page-two {
  background-color: var(--color-white);
  padding-top: 36px;
  padding-bottom: 72px;
}
.page-two-title {
  margin-bottom: 1rem;
}

.page-two-sub-title {
  color: var(--color-paragraph);
  margin: 0;
  padding: 0;
  font-size: var(--font-size-2xl);
  line-height: var(--line-height-tight);
  font-weight: var(--font-weight-bold);
}

.page-two-header {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-two-card-container {
  display: flex;
  gap: 24px 16px;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
}

.page-two-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: 1rem;
  padding: 16px;
}

.card-header {
  margin-bottom: 24px;
}

.card-header img {
  width: 5rem;
  height: 5rem;
  margin-top: 1rem;
  margin-left: 1rem;
}

.page-two-card h2 {
  color: var(--color-dark-blue);
  font-size: var(--font-size-2xl);
  line-height: var(--line-height-tight);
  font-weight: var(--font-weight-medium);
  margin-bottom: 1rem;
}

.page-two-card p {
  color: var(--color-paragraph);
  font-size: var(--font-size-lg);
  line-height: var(--line-height-normal);
  font-weight: var(--font-weight-bold);
}

/* breakpoint-lg */
@media screen and (min-width: 1024px) {
  .page-two-card {
    padding: 24px;
  }
}

/* breakpoint-xl */
@media screen and (min-width: 1280px) {
  .page-two-card-container {
    flex-direction: row;
    max-width: initial;
  }
}
