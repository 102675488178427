.button {
  outline: none;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  text-align: center;
  /* height: 55px; */
  padding: 20px 24px;
  cursor: pointer;
  /* margin-top: 20px; */
  font-size: var(--font-size-lg);
  font-family: "Inter", sans-serif;
  line-height: 1em;
  font-weight: var(--font-weight-medium);
  border-radius: 6px;
  color: var(--color-grey);
  transition: background-color 0.2s ease-in-out;
  transition: color 0.25s ease-in-out;
}

.button--primary {
  color: var(--color-white);
  background-color: var(--color-dark-blue);
}

/* .button--primary:hover {
} */

/* .button--secondary {
    
} */

.button--tertiary {
  background: transparent;
}

.button--plan {
  border: none;
  outline: none;
  color: var(--color-white);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  padding: 1rem 2rem;
  border-radius: 2rem;
  background: linear-gradient(
    90deg,
    rgba(34, 114, 255, 1) 0%,
    rgba(13, 3, 195, 1) 100%
  );
}

.button--plan:hover {
  transform: scale(1.025);
}

.button--pill {
  width: 100%;
  height: 45px;
  border-radius: 40px;
  background-color: var(--color-blue);
  color: var(--color-white);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button--trade-buy {
  color: var(--color-white);
  background-color: var(--color-trading-green);
  width: 100%;
  height: 45px;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button--trade-sell {
  color: var(--color-white);
  background-color: var(--color-trading-red);
  width: 100%;
  height: 45px;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* Edit Profile */

.button--edit-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  background-color: var(--color-primary-a0);
  color: white;
  border-radius: 10px;
  font-size: var(--font-size-sm);
  margin: 0;
  padding: var(--spacing-2);
  /* min-width: 125px; */
  max-height: 46px;
}

.button--edit-profile-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(130,130,151, 0.3);
  appearance: none;
  color: white;
  border-radius: 10px;
  font-size: var(--font-size-sm);
  margin: 0;
  padding: var(--spacing-2);
  /* min-width: 125px; */
  max-height: 46px;
}

.button--hover:hover {
  color: var(--color-blue);
}