.popover-container {
    position: relative;
    display: inline-block;
  }
  
  .popover-trigger {
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  
  .popover-box {
    position: absolute;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    min-width: 400px;
    z-index: 10000;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;
  }
  
  .popover-container:hover .popover-box {
    opacity: 1;
    pointer-events: auto;
  }
  
  /* Positioning */
  .popover-box.bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
  }
  
  .popover-box.top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10px;
  }
  
  .popover-box.left {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 10px;
  }
  
  .popover-box.right {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 10px;
  }
  