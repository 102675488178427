/* Mobile Styles */
.page-one {
  width: 100%;
  background-color: var(--color-light-blue);
  position: relative;
  padding-top: 24px;
  padding-bottom: 24px;
}

.overview-content {
  display: flex;
  flex-direction: column;
  max-width: var(--breakpoint-xl);
  margin: 0 auto;
}
.column-one {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.column-one h1 {
  font-size: var(--font-size-3xl);
  line-height: var(--line-height-tight);
  color: var(--color-dark-blue);
  margin: 0 0 24px 0;
  padding: 0;
}

.column-one p {
  margin: 0 0 28px 0;
  padding: 0;
}

.page-one-call-to-action {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0 32px;
  margin: 0 0 40px 0;
}
.call-to-action-video {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0;
  color: var(--color-cyan);
  background-color: var(--color-white);
  border: none;
  outline: none;
  border-radius: 50%;
}


.trading-view-link:hover {
  box-shadow: 2px 2px 4px 3px var(--box-shadow-color-hover);
}

.trading-view-link p {
  font-size: var(--font-size-2xl);
  line-height: var(--line-height-tight);
  font-weight: var(--font-weight-bold);
  text-align: right;
  margin: 0;
}

.column-two {
  display: none; /* Desktop only */
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 0;
}

.dashboard-image-mobile {
  display: block;
  width: 100%;
  border: solid 1px var(--color-paragraph);
  max-width: 600px;
  margin: 0 auto 60px auto;
  height: auto;
}

.page-one-background-image-top-right {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  z-index: 0;
  width: 25%;
  overflow-x: hidden;
}
.page-one-background-image-top-right-img {
  /* max-width: 300px;
  width: 100%;
  height: auto; */
}

.page-one-background-image-bottom-right {
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 25%;
  overflow: hidden;
}

.page-one-background-image-bottom-right-img {
  transform: rotate(180deg);
}

/* Desktop Styles */
@media screen and (min-width: 1024px) {
  .page-one {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .overview-content {
    flex-direction: row;
    gap: 0 32px;
  }

  .column-one h1 {
    font-size: var(--font-size-5xl);
    line-height: var(--line-height-tight);
  }

  .column-two {
    display: flex;
    width: 60%;
    flex-basis: 60%;
  }

  .dashboard-image-desktop {
    position: absolute;
    border: solid 1px var(--color-paragraph);
    top: 0;
    right: 0;
    /* max-width: 700px; */
    width: 100%;
    height: auto;
    z-index: 1;
  }
  .dashboard-image-mobile {
    display: none;
  }
}
