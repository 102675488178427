.card {
  display: flex;
  max-height: 100%;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-weight: var(--font-weight-medium);
  /* background-color: var(--background-cards); */
  /* box-shadow: 2px 2px 4px 3px var(--box-shadow-color); */
  /* border-radius: 4px; */
  transition: box-shadow 0.25s ease-in-out;
  /* cursor: pointer; */
}

/* .card:hover {
  box-shadow: 2px 2px 4px 3px var(--box-shadow-color-hover);
} */

.card-title {
  margin-bottom: 1rem;
}

.card-info {
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
}

.card-position-info {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-1);
  align-items: center;
}

.card-position-value {
  padding: 0;
  color: var(--text-primary);
}

.card-position-value > span {
  font-weight: var(--font-weight-medium);
}

.card-position-value-subtext {
  color: var(--color-text-primary);
  padding: 0;
}

.card-chart-display {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 185px;
  height: 150px;
  /* height: 100%; */
}

.portfolio-value-arrow {
  /* font-size: 150px; */
  height: 100%;
  overflow: hidden;
}

.portfolio-value-arrow img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.card-postion-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* @media screen and (min-width: 1024px) {
  .card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
  .card {
    max-width: initial;
    margin: 0;
    flex-direction: column;
    background-color: var(--color-white);
  }

  .card-chart-display {
    max-width: 700px;
    margin: 0 auto;
  }
} */
