.leaderboard-card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 20px;
    border: solid 1px var(--color-trading-outline);
}

.leaderboard-card-user {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px var(--color-trading-outline);
}

.leaderboard-card-user-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-1);
    margin: var(--spacing-2);
}

.leaderboard-card-account-container {
    display: flex;
    height: 4rem;
}
.leaderboard-card-username h1 {
    color: var(--color-primary-a0);
    text-decoration: underline;
}

.leaderboard-card-avg-profit {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: var(--spacing-1 );
}

.leaderboard-card-user-rank {
    margin: var(--spacing-2);
}

.leaderboard-card-data-container {
    display: flex;
    flex-direction: row;
}

.leaderboard-card-data {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: start;
    padding: var(--spacing-2);
}

.leaderboard-card-data:nth-child(2) {
    border-left: solid 1px var(--color-trading-outline);
    border-right: solid 1px var(--color-trading-outline);
}