@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); /* To Do: remove if not needed */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box; /* This is a big change but should be used globally */
}

html,
body,
#root {
  font-family: "Inter", "Roboto", sans-serif;
  line-height: var(--line-height-normal);
  /* background-color: var(--background-primary); */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* general element reset */
h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
input {
  margin: 0;
  padding: 0;
  font-weight: var(--font-weight-medium);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* 
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

/* Global Utilities / Design System */
:root {
  /* grabbed from Tailwind - https://tailwindcss.com/docs/screens */
  --breakpoint-sm: 640px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1280px;
  --breakpoint-2xl: 1536px;

  /* z-index */
  --max-z-index: 1000;

  /* box shadow */
  --box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);

  /* colors */
  --color-border: rgba(0, 0, 0, 0.25);
  --color-error : #FF9494;
  --color-paragraph: #9090a7;
  --color-white: #ffffff;
  --color-black: #000;
  --color-grey: #828297;
  --color-logo-grey: #404040;
  --color-line-grey: #eeeeee;
  --color-green: #139c21;
  --color-cyan: #00e5cc;
  --color-blue: #007dfc;
  --color-faded-blue: #526cc8;
  --color-light-blue: #ecf9ff;
  --color-dark-blue: #060640;
  --color-info-dark: #01579b;
  --color-text-primary: rgba(0, 0, 0, 0.87);
  --color-text-grey: rgb(91, 97, 110);
  --color-trading-background: #131722;
  --color-trading-black-active: #141519;
  --color-trading-black-inactive: #282B30;
  --color-trading-green: #26AD74;
  --color-trading-red: #F0616D;
  --color-trading-outline: #8a919e33;

  --background-primary-light: #fff;
  --background-secondary-light: #EEE;
  --background-primary-dark: #141b2d;
  --background-secondary-dark: #142a40;
  /* --background-secondary-dark: #212529; */

  --text-dark-primary: #e0e0e0;

  /** Dark theme primary colors */
  --color-primary-a0: #007dfc;
  --color-primary-a10: #4a8afd;
  --color-primary-a20: #6a98fe;
  --color-primary-a30: #85a6fe;
  --color-primary-a40: #9cb4ff;
  --color-primary-a50: #b1c2ff;

  --color-open-positions-table: #5898da;

  --color-peach: #ff8a66;

  /* Old Glass Colors */
  /* --color-glass-red: #FF5252;
  --color-glass-green: #35C53A; */
  --color-glass-green: #00A652;
  --color-glass-red: #ED1B24;

  /* --light-glass-background: linear-gradient(135deg, rgba(106,152,254,1) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 60%, rgba(156,180,255,1) 100%); */
  /* --light-glass-background: radial-gradient(circle, rgba(0,125,252,1) 0%, rgba(156,180,255,1) 100%); */
  --light-glass-background: linear-gradient(to bottom, #f1f4f9, #dff1ff);

  --dark-glass-background: radial-gradient(circle, rgba(30,30,30,1) 0%, rgba(15,15,15,1) 100%);

  --gradient-peachy: 
    radial-gradient(at 2% 78%, #b8b7e1 0px, transparent 50%), 
    radial-gradient(at 50% 7%, #f4c071 0px, transparent 50%), 
    radial-gradient(at 89% 10%, #ff8a66 0px, transparent 50%), 
    radial-gradient(at 77% 85%, #413ee5 0px, transparent 50%), 
    radial-gradient(at 0% 100%, #9999ff 0px, transparent 50%), 
    radial-gradient(at 0% 0%, #f4f4f6 0px, transparent 50%);

  /* font sizes 
   https://tailwindcss.com/docs/font-size 
  */
  --font-size-xxs: 0.60rem;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.875rem;
  --font-size-4xl: 2.25rem;
  --font-size-5xl: 3rem;
  --font-size-6xl: 3.75rem;
  --font-size-7xl: 4.5rem;
  --font-size-8xl: 6rem;
  --font-size-9xl: 8rem;

  /* Spacing */
  --spacing-1: 8px;
  --spacing-1\.5: 12px;
  --spacing-2: 16px;
  --spacing-3: 24px;
  --spacing-4: 32px;
  --spacing-5: 40px;
  --spacing-6: 48px;
  --spacing-7: 56px;
  --spacing-8: 64px;
  --spacing-9: 72px;
  --spacing-10: 80px;

  /* line-height
  https://tailwindcss.com/docs/line-height
  */
  --line-height-3: 0.75rem; /* 12px */
  --line-height-4: 1rem; /* 16px */
  --line-height-5: 1.25rem; /* 20px */
  --line-height-6: 1.5rem; /* 24px */
  --line-height-7: 1.75rem; /* 28px */
  --line-height-8: 2rem; /* 32px */
  --line-height-9: 2.25rem; /* 36px */
  --line-height-10: 2.5rem; /* 40px */
  --line-height-none: 1;
  --line-height-tight: 1.25;
  --line-height-snug: 1.375;
  --line-height-normal: 1.5;
  --line-height-relaxed: 1.625;
  --line-height-loose: 2;

  /* font-weight */
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
}

body {
  --background-primary: var(--background-primary-light);
  --background-secondary: var(--background-secondary-light);
  --background-cards: var(--background-primary-light);
  --background-signout: var(--color-light-blue);
  --text-primary: var(--color-text-primary);
  --text-secondary: var(--color-text-grey);
  --link-header: var(--color-info-dark);
  --logo-secondary: var(--color-logo-grey);
  --box-shadow-color: rgba(0, 0, 0, 0.25);
  --box-shadow-color-hover: rgba(0, 0, 0, 0.5);
  --table-background: var(--background-primary-light);
  
  --background-trading: var(--background-secondary);
  --background-trading-action-active: white;
  --background-trading-action-inactive: var(--background-secondary);

  --circle-background1: var(--color-primary-a40);
  --circle-background2: var(--color-primary-a50);
  --circle-background3: var(--color-peach);

  --glass-background: var(--light-glass-background);

  --glass-layout-bg: rgba(255, 255, 255, 0.8);
  
  --glass-component-bg: rgba(255, 255, 255, 0.7);
}

body.dark {
  --background-primary: var(--background-primary-dark);
  --background-secondary: var(--background-secondary-dark);
  --background-cards: var(--background-secondary);
  --background-signout: var(--background-secondary-dark);
  --text-primary: var(--text-dark-primary);
  --text-secondary: #9d9d9d;
  --link-header: var(--text-dark-primary);
  --logo-secondary: var(--text-dark-primary);
  --box-shadow-color: rgba(224, 224, 224, 0.25);
  --box-shadow-color-hover: rgba(224, 224, 224, 0.5);
  --table-background: var(--background-secondary);

  --background-trading: var(--color-trading-background);
  --background-trading-action-active: var(--color-trading-black-active);
  --background-trading-action-inactive: var(--color-trading-black-inactive);

  --circle-background1: rgba(72, 29, 109, 0.25);
  --circle-background2: rgba(43, 17, 65, 0.25);
  --circle-background3: rgba(143, 57, 218, 0.25);

  --glass-background: #121212;
  /* --glass-background: var(--dark-glass-background); */

  --glass-layout-bg: rgba(255, 255, 255, 0.3);

  --glass-component-bg: rgba(255, 255, 255, 0.2);
}

/* Utility Classes */

.glass-surface {
  background: var(--glass-layout-bg); /* Light and semi-transparent */
  backdrop-filter: blur(10px); /* Blur for frosted effect */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Optional border */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}

.glass-surface-module {
  background: var(--glass-component-bg); /* Light and semi-transparent */
  backdrop-filter: blur(10px); /* Blur for frosted effect */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Optional border */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
  border-radius: 10px;
  padding: var(--spacing-4);
}

.page-wrapper {
  max-width: var(--breakpoint-xl);
  width: 100%;
  margin: 0 auto;
}

/* Typography */
.text-paragraph {
  color: var(--color-paragraph);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-normal);
}

.text-heading {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-weight-tight);
}

.text-hero-large {
  font-size: var(--font-size-6xl);
}

.text-hero {
  font-size: var(--font-size-4xl) !important;
}

.text-header {
  font-size:  var(--font-size-2xl);
}

.text-base {
  font-size: var(--font-size-base);
}

.text-small {
  font-size: var(--font-size-sm);
}

.text-xsmall {
  font-size: var(--font-size-xs);
}

.text-tiny {
  font-size: var(--font-size-xxs);
}

/* Text weight */
.weight-regular {
  font-weight: var(--font-weight-regular);
}
.weight-medium {
  font-weight: var(--font-weight-medium);
}
.weight-semi-bold {
  font-weight: var(--font-weight-semi-bold);
}
.weight-bold {
  font-weight: var(--font-weight-bold);
}

@media screen and (min-width: 1024px) {
  .text-heading {
    font-size: var(--font-size-5xl);
  }
}
/* text colors */
.text-white {
  color: var(--color-white);
}
.text-black {
  color: var(--color-black);
}
.text-grey {
  color: var(--color-grey);
}
.text-logo-grey {
  color: var(--color-logo-grey);
}
.text-green {
  color: var(--color-green);
}
.text-cyan {
  color: var(--color-cyan);
}
.text-blue {
  color: var(--color-blue);
}
.text-faded-blue {
  color: var(--color-faded-blue);
}
.text-light-blue {
  color: var(--color-light-blue);
}
.text-dark-blue {
  color: var(--color-dark-blue);
}

.text-color-primary {
  color: var(--text-primary);
}
.text-color-secondary {
  color: var(--text-secondary);
}
.text-primary-a0 {
  color: var(--color-primary-a0);
}
.text-primary-a10 {
  color: var(--color-primary-a10);
}
.text-primary-a20 {
  color: var(--color-primary-a20);
}
.text-primary-a30 {
  color: var(--color-primary-a30);
}
.text-primary-a40 {
  color: var(--color-primary-a40);
}
.text-primary-a50 {
  color: var(--color-primary-a50);
}

.text-glass-red {
  color: var(--color-glass-red) !important;
}

.text-glass-green {
  color: var(--color-glass-green) !important;
}

.grid-row2 {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  gap: var(--spacing-4);
}


.grid-column3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: var(--spacing-4);
}

.grid-column4 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-4);
}
