.lb-table {
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-4);
}

.lb-table-container {
    margin-top: var(--spacing-4);
    border-radius: 20px;
    border: solid 1px var(--color-trading-outline);
}

.lb-table-scrollable-container {
    display: flex;
    flex-direction: column;
}

.lb-table-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    /* background-color: #fafafa; */
    background-color: var(--color-primary-a0);
    border-top-left-radius: 20px;
    color: white;
    font-weight: 600;
    border-top-right-radius: 20px;
    border-bottom: solid 1px var(--color-trading-outline);
    overflow: scroll;
}

.lb-table-footer {
    display: flex;
    flex-direction: row;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    justify-content: space-between;
    padding: var(--spacing-2);
    /* background-color: #fafafa; */
    background-color: var(--color-primary-a0);
    color: white;
    font-weight: 600;
    border-bottom: solid 1px var(--color-trading-outline);
}

.lb-table-page-select-container {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-2);
    align-items: center;
    height: 1rem;
}

.lb-table-page-select-number {
    cursor: pointer;
}
.lb-table-page-select-number-active {
    display: flex;
    background-color: lightgray;
    border-radius: 50%;
    width: 1rem;
    justify-content: center;
    align-items: center;
}

.lb-table-page-select-button {
    cursor: pointer;
}

.lb-table-page-select-button-disabled {
    opacity: 0.5;
    pointer-events: none;
}

/* .lb-table-header-col {
    margin: var(--spacing-2);
    flex: 1;
} */

.lb-table-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    background-color: #FFF;
    overflow: scroll;
}

.lb-table-row:not(:last-child) {
    border-bottom: solid 1px var(--color-trading-outline);
}

/* .lb-table-row:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
} */

.lb-table-col {
    margin: var(--spacing-2);
    flex: 1;
}

.lb-table-col:not(:first-child) {
    flex: 2;
}

.lb-table-col:nth-child(2) {
    flex: 2;
}

.lb-table-username {
    color: var(--color-primary-a0);
    text-decoration: underline;
}