.integration-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    max-width: 4fr;
    gap: var(--spacing-4);
    padding: var(--spacing-4);
}

.integration-tile {
    display: flex;
    flex-direction: column;
    aspect-ratio: 1 / 1;
    align-items: center;
    gap: var(--spacing-2);
    text-align: center;
}

.integration-tile.enabled {
    cursor: pointer;
}

.integration-tile.enabled:hover {
    box-shadow: 2px 2px 4px 3px var(--color-primary-a50);
}

.tile-image {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 50%;
    aspect-ratio: 1 / 1;
}

.tile-image.black {
    background-color: black;
}

.tile-image img {
    z-index: 1000;
    width: 100%;
}